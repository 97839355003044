import React from 'react'
import styled from 'styled-components'
import {Link} from 'gatsby'
import {Inner} from '../../styles'

const PageHeaderStyled = styled.div`
  background: ${props => props.theme.grey3};
  color: ${props => props.theme.grey1};
  margin-bottom: ${({theme, flush}) => (flush ? '0' : theme.vPadding)};
  text-align: center;
  padding: 30px 0;
  h1 {
    color: ${props => props.theme.grey2};
    font-weight: 600;
    margin: 0 0 10px 0;
  }
`
const Breadcrumbs = styled.div`
  span,
  a {
    text-transform: capitalize;
    color: ${props => props.theme.grey1};
    padding: 10px 10px 10px 12px;
    position: relative;
    &:not(:first-child):before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid ${props => props.theme.grey2};
      opacity: 0.5;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      position: absolute;
      top: calc(50% - 5px);
      left: 0;
    }
    text-decoration: none;
  }
  a:hover {
    color: ${props => props.theme.grey1};
    text-decoration: underline;
  }
`

const PageHeader = ({children, location, flush, hideCrumbs = false}) => {
  let crumbs = ''
  if (location && location.pathname && !hideCrumbs) {
    const paths = location.pathname.split('/')
    // remove blanks
    crumbs = paths.filter(el => el !== '')
    // remove 'App'
    const index = crumbs.indexOf('app')
    if (index > -1) {
      crumbs.splice(index, 1)
    }
  }

  return (
    <PageHeaderStyled flush={flush}>
      <Inner>
        <h1>{children}</h1>
        <Breadcrumbs>
          <Link to="/">Home</Link>
          {crumbs !== '' &&
            crumbs.map((crumb, i) =>
              crumbs.length > 1 && i === 0 ? (
                <Link to={`/${crumb}/`} key={crumb}>
                  {crumb.replace(/-/g, ' ')}
                </Link>
              ) : (
                <span key={crumb}>{crumb.replace(/-/g, ' ')}</span>
              ),
            )}
        </Breadcrumbs>
      </Inner>
    </PageHeaderStyled>
  )
}

export default PageHeader
